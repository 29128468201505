import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Spinner from '../Spinner/Spinner';

class Photo extends Component {
    render() {
        const { post, index, comments } = this.props;
        const reroute = this.props.match.path === '/' ? `/view/${post.id}` : '/'
        let photo = <Spinner />
        if (post) {
            photo = (
                <figure className="grid-figure">
                    <div className="grid-photo-wrap">
                        <Link to={reroute}>
                            <div className="imgWrapper">
                                <img src={post.imageUrl} alt='Natalia' className='grid-photo'  />
                            </div>
                        </Link>

                        <TransitionGroup>
                            <CSSTransition
                                classNames="like"
                                key={post.likes}
                                timeout={500}
                            >
                                <span className="likes-heart">{post.likes}</span>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>

                    <figcaption>
                        <p>{post.caption}</p>
                        <div className="control-buttons">
                            <button
                                onClick={() => this.props.incrementLikes(index)}
                                className="likes"
                            > <span role="img" aria-label="vomit">❤️</span> {post.likes}</button>
                            <Link className='button' to={`/view/${post.id}`}>
                                <span className="comment-count">
                                    <span className="speech-bubble"></span>
                                    &nbsp;{comments[post.id] ? comments[post.id].length : 0}
                                </span>
                            </Link>
                        </div>
                    </figcaption>
                </figure>
            )
        }
        return (
            <>
                {photo}
            </>
        );
    }
}

export default Photo;