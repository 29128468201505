import React from 'react';

const Footer = (props) => {
    const style={width: '100%', }
    return (
        <div className="footer">
            <hr style={style} />
            <footer>

                
                <div className='italics'>
                    2019 &copy; Radek Szumski
            </div>
            </footer>
        </div>
    );
};

export default Footer;