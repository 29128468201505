import React from 'react';
import { Link } from 'react-router-dom';

const Aboutme = (props) => {
    const center = { textAlign: 'center' };
    const justify = { textAlign: 'justify' };
    return (
        <div className="aboutme">
            <header>
                <button>
                    <Link to='/'>Go back</Link>
                </button>
            </header>
            <main>
                <p style={center}>
                    Hello there my fans!!!
                </p>
                
            </main>
        </div>
    );
};

export default Aboutme;