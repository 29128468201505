// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA6InNgQ5wnSk_2RkLk1TO7kZwvkc1ayQg",
    authDomain: "natistagram-firebase.firebaseapp.com",
    projectId: "natistagram-firebase",
    storageBucket: "natistagram-firebase.appspot.com",
    messagingSenderId: "887811187372",
    appId: "1:887811187372:web:b764a8d59426e7c32bb509"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);